import { Component, Input, OnInit } from '@angular/core';
import { SupportService } from 'src/app/service/support.service';
import { VerificationService } from 'src/app/service/verification.service';

@Component({
  selector: 'app-announcement-banner',
  templateUrl: './announcement-banner.component.html',
  styleUrls: ['./announcement-banner.component.scss']
})
export class AnnouncementBannerComponent implements OnInit {

  finalMessage: string = '';
  finalSubject: string = '';
  isModalOpen = false;
  detailsSubmitted = false;
  @Input() selectedPlan: string;
  @Input() selectedSubscription: string;

  formData = {
    name: '',
    mobile: '',
    email: ''
  };

  constructor(private apiService: SupportService, private _verification: VerificationService) { }


  ngOnInit() {
    setTimeout(() => {
      this.claimOffer();
    }, 6000)
  }

  submitForm() {
    document.body.style.overflow = 'auto';
    this.finalMessage = this.constructFinalMessage();
    this.finalSubject = this.constructFinalSubject();
    this.sendOtp(this.formData.name, this.formData.email, this.formData.mobile);
  }

  isFormValid(): boolean {
    return this.formData.name.trim() !== '' &&
      this.formData.mobile.trim() !== '' &&
      this.formData.email.trim() !== '';
  }

  claimOffer() {
    this.isModalOpen = true;
  }

  closeForm() {
    this.isModalOpen = false;
    this.detailsSubmitted = false;
    this.formData = {
      name: '',
      mobile: '',
      email: ''
    };
  }

  sendOtp(name: string, email: string, mobile: string) {
    this._verification.sendOTP(name, email, mobile).subscribe(response => {
      this.detailsSubmitted = true;
      sessionStorage.setItem('securedActionId', response.id)
    });
  }

  sendDetailsToSupport() {
    this.apiService.sendFormData(this.formData.email, this.finalMessage, this.finalSubject)
      .subscribe(response => {
        this.closeForm();
      });
  }

  onOtpChange(input: any) {
    if (input.length == 4) {
      this._verification.verifyOTP(sessionStorage.getItem('securedActionId'), input).subscribe(response => {
        sessionStorage.removeItem('securedActionId');
        this.sendDetailsToSupport();
      });
    }
  }

  resendOTP() {
    this._verification.resendOTP(sessionStorage.getItem('securedActionId')).subscribe(response => {
    });
  }

  constructFinalSubject(): string {
    return `[STORE Enquiry] ${this.selectedPlan} : ${this.selectedSubscription} ${this.formData.email}\n`;
  }

  constructFinalMessage(): string {
    return `Name: ${this.formData.name} \n` +
      `Email: ${this.formData.email}\n` +
      `Phone: ${this.formData.mobile}\n`
  }
}
