import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cariq-tls-cloud',
  templateUrl: './cariq-tls-cloud.component.html',
  styleUrls: ['./cariq-tls-cloud.component.scss']
})
export class CariqTlsCloudComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
