import { Component, OnInit } from '@angular/core';
import { ROUTES } from 'src/app/constants/Routes';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isOpen = false; // Tracks sidebar state (open/closed)
  Routes = ROUTES;

  toggleSidebar() {
    this.isOpen = !this.isOpen;
  }

  constructor() { }

  ngOnInit() {

  }

}
