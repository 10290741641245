import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {

  @Input() mainImage: string;
  @Input() images: string[];

  constructor() { }

  ngOnInit() {
  }

  changeImage(image: string) {
    this.mainImage = image;
  }

}
