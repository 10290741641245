import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-refund-and-return',
  templateUrl: './refund-and-return.component.html',
  styleUrls: ['./refund-and-return.component.scss']
})
export class RefundAndReturnComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });

  }

}
