import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StoreRoutingModule } from './store-routing.module';
import { ImageGalleryComponent } from './store-ui-kit/image-gallery/image-gallery.component';
import { AppStoreAndRatingComponent } from './store-ui-kit/app-store-and-rating/app-store-and-rating.component';
import { AnnouncementBannerComponent } from './store-ui-kit/announcement-banner/announcement-banner.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgOtpInputModule } from 'ng-otp-input';
import { DiscountTimerComponent } from './store-ui-kit/discount-timer/discount-timer.component';

@NgModule({
  declarations: [ImageGalleryComponent, AppStoreAndRatingComponent, AnnouncementBannerComponent, DiscountTimerComponent],
  imports: [
    CommonModule,
    StoreRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgOtpInputModule
  ],
  exports: [ImageGalleryComponent, AppStoreAndRatingComponent, AnnouncementBannerComponent, DiscountTimerComponent]
})
export class StoreModule { }
