import { Component, OnInit } from '@angular/core';
import { ROUTES } from 'src/app/constants/Routes';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
  Routes = ROUTES;

  jobs = [
    {
      title: "Lead/Senior Manager- Marketing & Communication",
      experience: "10-15 years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time",
      description: "The Senior Manager - Lead Marketing & Communication will focus on brand positioning, go-to-market strategies, and customer engagement initiatives.",
      route: this.Routes.marcom_jd,
      tags: ["2-3 years", "Pune, Maharashtra", "Full Time"]
    },
    {
      title: "Project Manager(IT)",
      experience: "6-9 years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time",
      description: "The Project Manager leads projects, ensuring timely delivery with Agile methods, managing teams, risks, and stakeholders.",
      route: this.Routes.projectmanager_jd,
      tags: ["6-9 years", "Pune, Maharashtra", "Full Time"]
    },
    {
      title: "Product Manager",
      experience: "5-7 years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time",
      description: "The Product Manager shapes product vision, manages its lifecycle, and collaborates with teams to deliver quality solutions.",
      route: this.Routes.productmanager_jd,
      tags: ["5-7 years", "Pune, Maharashtra", "Full Time"]
    },
    {
      title: "Lead/Manager-MobileApp Development",
      experience: "10-12 years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time",
      description: "The Lead/Manager-MobileApp Development oversees mobile app development, leading developers to build quality iOS/Android apps and driving innovation.",
      route: this.Routes.mobilelead_jd,
      tags: ["10-12 years", "Pune, Maharashtra", "Full Time"]
    },
    {
      title: "iOS Developer",
      experience: "5-7 years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time",
      description: "Looking for an iOS Developer to build cutting-edge mobile applications tailored for Apple's ecosystem.",
      route: this.Routes.ios_jd,
      tags: ["5-7 years", "Pune, Maharashtra", "Full Time"]
    },
    {
      title: "DevOps Engineer",
      experience: "5-7 years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time",
      description: "We are looking for a DevOps Engineer with expertise in cloud solutions, CI/CD pipelines, and monitoring tools to support deployment and infrastructure.",
      route: this.Routes.devops_jd,
      tags: ["5-7 years", "Pune, Maharashtra", "Full Time"]
    },
    {
      title: "Java Developer",
      experience: "5-7 years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time",
      status:"Currently Not Hiring",
      description: "A seasoned Java Developer is required to design, develop, and maintain high-performance, scalable, and secure Java applications.",
      route: this.Routes.java_jd,
      tags: ["5-7 years", "Pune, Maharashtra", "Full Time","Currently Not Hiring"]
    },
    {
      title: "Android Developer",
      experience: "5-7 years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time",
      status:"Currently Not Hiring",
      description: "We are hiring an Android Developer to create high-quality mobile applications, ensuring performance and responsiveness.",
      route: this.Routes.android_jd,
      tags: ["5-7 years", "Pune, Maharashtra", "Full Time","Currently Not Hiring"]
    },
    
    
    {
      title: "Sales & Customer Support Specialist",
      experience: "0-2 years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time - 6 Working Days",
      status:"Currently Not Hiring",
      description: "This role involves managing customer inquiries, ensuring client satisfaction, and supporting sales operations.",
      route: this.Routes.customer_support_jd,
      tags: ["0-2 years", "Pune, Maharashtra", "Full Time - 6 Working Days","Currently Not Hiring"]
    },
    {
      title: "Jr. QA Engineer",
      experience: "2+ years",
      location: "Pune, Maharashtra",
      employmentType: "Full Time",
      status:"Currently Not Hiring",
      description: "We need a Junior QA Engineer to test, debug, and ensure the quality of software applications while adhering to industry standards.",
      route: this.Routes.jr_qa_jd, // Replace with the actual route if available
      tags: ["2+ years", "Pune, Maharashtra", "Full Time","Currently Not Hiring"]
    }
  ];
  
  
  

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }

}
