import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-customer-support-specialist',
  templateUrl: './customer-support-specialist.component.html',
  styleUrls: ['./customer-support-specialist.component.scss']
})
export class CustomerSupportSpecialistComponent implements OnInit {

  selectedJob = 'Lead/Senior Manager- Marketing & Communication';

  roles = [
    "Respond to customer inquiries via phone, email, and chat with empathy and professionalism.",
    "Actively listen to customer needs and identify opportunities to provide tailored solutions and support.",
    "Take the initiative to identify and resolve customer issues before they escalate.",
    "Collaborate with team members to improve service processes and share insights.",
    "Provide feedback and suggestions to enhance the customer experience.",
    "Maintain accurate records of customer interactions, sales activities, and follow-ups.",
    "Contribute to a positive team environment by being open to feedback and supporting colleagues."
  ];

  skills = [
    "Qualification: Bachelors / master's degree required",
    "0-2 years of experience in customer support or a related field.",
    "Strong communication skills with an emphasis on active listening.",
    "A friendly, approachable attitude with a customer-first mindset.",
    "Ability to accept and implement feedback for personal and professional growth.",
    "Proactive problem-solving skills and the ability to remain calm under pressure.",
    "Familiarity with customer support software like Freshdesk, FSM, and tools is a plus.",
    "Proficiency in MS Office (Excel, Word, PPT, etc).",
    "Should have knowledge about FSM, Freshdesk, and Ticketing System."
  ];

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }
}
