import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-manager',
  templateUrl: './product-manager.component.html',
  styleUrls: ['./product-manager.component.scss']
})
export class ProductManagerComponent implements OnInit {

  selectedJob = 'Product Manager ';

  roles = [
    'Define the product strategy and roadmap.',
    'Manage the product throughout its lifecycle from concept to end-of-life.',
    'Collect, manage, and maintain traceability of requirements from all stakeholders across product releases.',
    'Write the business case for investments and gain internal approval.',
    'Propose a product marketing plan and budget and then deliver against it.',
    'Write Product Requirements and Market Requirements documents.',
    'Work with internal stakeholders and third parties to assess and establish partnerships.',
    'Develop core positioning and messaging for the product.',
    'Perform product demos to customers and develop relationships in key clients (if B2B).',
    'Lead the virtual product team to ensure delivery of agreed objectives.',
    'Set pricing.',
    'Monitor, report, and improve product performance.',
    'Run trials and MVPs, develop and deliver go-to-market launch plans.',
    'Commission market and competitor research.',
    'Promote the product to sales teams through sales training sessions, webinars, newsletters, and other mechanisms.',
    'Develop and deliver training to technical sales support.',
    'Manage development and marketing projects to deliver to plan.',
    'Collate feedback from sales and customers.',
    'Represent the voice of the customer in senior-level management meetings.',
    'Evangelize the product internally and externally.',
    'Be recognized as a leader in the business and build relations with business stakeholders.',
    'Deliver a regular (e.g., monthly) volume, revenue, and profit forecast.'
  ];

  skills = [
    'Ability to develop product vision and roadmap based on stakeholder input, market research, and industry insight.',
    'Strong collaboration skills to work with Product Head, Development, Engineering, and other teams.',
    'Experience in creating and maintaining marketing plans and collateral (e.g., web updates, training materials).',
    'Forecasting skills using input from marketing, sales, and market analysts.',
    'Ability to identify key benefits and value propositions for products.'
  ];

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }
}