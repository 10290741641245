import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cariq-cloud',
  templateUrl: './cariq-cloud.component.html',
  styleUrls: ['./cariq-cloud.component.scss']
})
export class CariqCloudComponent implements OnInit {
  cloudToShow = 0;
  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });

  }

  showCarIQCloud(sectionToShow) {
    this.cloudToShow = sectionToShow
  }

}
