import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-discount-timer',
  templateUrl: './discount-timer.component.html',
  styleUrls: ['./discount-timer.component.scss']
})
export class DiscountTimerComponent implements OnInit {
  hours: number = 24; // Initial hours
  minutes: number = 0; // Initial minutes
  seconds: number = 0; // Initial seconds
  private countdownInterval: any;
  private endTime: number; // The end time in milliseconds

  ngOnInit(): void {
    this.setEndTime();
    this.startCountdown();
  }

  setEndTime(): void {
    // Set the end time 24 hours from now
    const now = new Date().getTime();
    this.endTime = now + 24 * 60 * 60 * 1000; // 24 hours in milliseconds
  }

  startCountdown(): void {
    this.countdownInterval = setInterval(() => {
      this.updateCountdown();
    }, 1000); // Update every second
  }

  updateCountdown(): void {
    const now = new Date().getTime();
    const timeDifference = this.endTime - now;

    if (timeDifference <= 0) {
      // If the countdown has reached or passed the end time, reset it
      this.resetCountdown();
    } else {
      // Calculate hours, minutes, and seconds from the remaining time
      this.hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
      this.minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
      this.seconds = Math.floor((timeDifference / 1000) % 60);
    }
  }

  resetCountdown(): void {
    this.hours = 24;
    this.minutes = 0;
    this.seconds = 0;
    this.setEndTime(); // Set a new end time 24 hours from now
  }

  formatTime(value: number): string {
    // Ensure that the time is always shown as two digits
    return value < 10 ? '0' + value : value.toString();
  }

  ngOnDestroy(): void {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval); // Cleanup the interval on component destruction
    }
  }
}
