import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tls-solution',
  templateUrl: './tls-solution.component.html',
  styleUrls: ['./tls-solution.component.scss']
})
export class TlsSolutionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });

  }

}
