import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  private sendOTPUrl = 'https://dev-ecu.mycariq.com/Cariq/verify/customer';
  private verifyOTPUrl = 'https://dev-ecu.mycariq.com/Cariq/security/action/execute';
  private resendOTPUrl = 'https://dev-ecu.mycariq.com/Cariq/security/request/otp';

  constructor(private http: HttpClient) { }

  sendOTP(name: string, email: string, mobile: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Basic Y2FyaXFfYXJjaGl2ZWQ6NGU4MGRlYWY2N2FmOThlOTg4MTE3ZmNiODAzNmJjNDE='
    });
    headers.append("Content-Type", "application/json");
    return this.http.post<any>(this.sendOTPUrl, { "name": name, "emailId": email, "mobileNo": mobile }, { headers });
  }

  verifyOTP(securedActionId: string, securityKey: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Basic Y2FyaXFfYXJjaGl2ZWQ6NGU4MGRlYWY2N2FmOThlOTg4MTE3ZmNiODAzNmJjNDE='
    });
    headers.append("Content-Type", "application/json");
    return this.http.post<any>(this.verifyOTPUrl, { securedActionId: securedActionId, securityKey: securityKey }, { headers });
  }

  resendOTP(id: string): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Basic Y2FyaXFfYXJjaGl2ZWQ6NGU4MGRlYWY2N2FmOThlOTg4MTE3ZmNiODAzNmJjNDE='
    });
    headers.append("Content-Type", "application/json");
    return this.http.post<any>(this.resendOTPUrl, { id: id }, { headers });
  }
}
