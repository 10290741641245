import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-android-developer-jd',
  templateUrl: './android-developer-jd.component.html',
  styleUrls: ['./android-developer-jd.component.scss']
})
export class AndroidDeveloperJdComponent implements OnInit {

  selectedJob = 'Android Developer';

  roles = [
    'Design, develop, and maintain advanced applications for the Android platform.',
    'Collaborate with cross-functional teams to define, design, and ship new features.',
    'Ensure the performance, quality, and responsiveness of applications.',
    'Identify and correct bottlenecks and fix bugs.',
    'Continuously discover, evaluate, and implement new technologies to maximize development efficiency.',
    'Work on improving application performance and user experience.',
    'Collaborate with UI/UX designers to create user-friendly interfaces.',
    'Write clean, maintainable code and conduct unit tests',
    'Perform code reviews and troubleshoot/debug applications.',
    'Maintain detailed documentation of the development process, architecture, and standard components.',
    'Create technical specifications and documentation.'
  ];

  skills = [
    '5-6 years of professional experience in Android development.',
    'Strong knowledge of Android SDK, different versions of Android, and how to deal with different screen sizes.',
    'Proficiency in Kotlin and Java programming languages.',
    'Experience with RESTful APIs to connect Android applications to back-end services.',
    'Familiarity with cloud message APIs and push notifications.',
    'Understanding of mobile development life cycle.',
    'Experience with offline storage, threading, and performance tuning.',
    'Knowledge of the open-source Android ecosystem and the libraries available for common tasks.',
    'Proficiency in version control systems like Git.',
    'Strong analytical and problem-solving skills.',
    'Ability to think critically and work independently.',
    'Experience with development tools like Android Studio, Gradle, and Bitbucket.',
    'Familiarity with Agile/Scrum methodologies.',
    'Knowledge of Flutter is a plus.',
    'Experience with integrating third-party libraries and APIs.',
    'Familiarity with UI/UX design principles and tools.'
  ];

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }
}
