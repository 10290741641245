import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-app-store-and-rating',
  templateUrl: './app-store-and-rating.component.html',
  styleUrls: ['./app-store-and-rating.component.scss']
})
export class AppStoreAndRatingComponent implements OnInit {
  @ViewChild('testimonialContainer') testimonialContainer: ElementRef;
  showMore: boolean = false;

  toggleShowMore() {
    this.showMore = !this.showMore;
  }

  scrollLeft() {
    this.testimonialContainer.nativeElement.scrollBy({
      left: -this.testimonialContainer.nativeElement.offsetWidth,
      behavior: 'smooth'
    });
  }

  scrollRight() {
    this.testimonialContainer.nativeElement.scrollBy({
      left: this.testimonialContainer.nativeElement.offsetWidth,
      behavior: 'smooth'
    });
  }

  constructor() { }

  ngOnInit() {
  }

}
