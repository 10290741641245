import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cyber-security',
  templateUrl: './cyber-security.component.html',
  styleUrls: ['./cyber-security.component.scss']
})
export class CyberSecurityComponent implements OnInit {

  securityMeasures = [
    "Authentication: Utilizes JWT Bearer Token and OAuth2 protocols.",
    "API: Secured, private, and role-based access.",
    "Firewall and Ports: Regular penetration testing to assess attack vectors.",
    "Communication Protocol: Uses HTTPS, encrypted TCP/IP, and MQTT.",
    "Access Roles and Restrictions: Defined roles such as User, Observer, and Admin.",
    "Data-Action Separation: Implements safeguards against injection attacks.",
    "We use Uptane software for updating the security system for the automotive industry."
  ];

  mobileApplications = [
    "Data Encryption: Local data is encrypted.",
    "Code Obfuscation: Uses techniques to prevent decompilation.",
    "Login Security: OTP-based login mechanism.",
    "Third-Party Libraries: Utilizes certified third-party libraries."
  ];

  webApplications = [
    "OWASP XSS Prevention: Follows OWASP Cheat Sheet for XSS prevention.",
    "Best Practices: Adheres to OWASP's recommended security practices."
  ];

  system = [
    "Malicious Activity Alerts: System alerts for detecting malicious activities.",
    "Access Control: Limited admin access restricted to 2 or 3 individuals.",
    "Password Policy: Monthly password change policy in effect."
  ];

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }

}
