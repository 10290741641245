import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mobileapp-leadmanager-jd',
  templateUrl: './mobileapp-leadmanager-jd.component.html',
  styleUrls: ['./mobileapp-leadmanager-jd.component.scss']
})
export class MobileappLeadmanagerJdComponent implements OnInit {
    
  selectedJob = 'Lead/Manager-MobileApp Development';

  roles = [
    'Leadership and Management: Lead, mentor, and manage a team of mobile app developers.',
    'Leadership and Management: Foster a collaborative and innovative work environment, encouraging team growth and development.',
    'Technical Oversight: Design and architect scalable, high-performance mobile applications for both iOS and Android platforms.',
    'Technical Oversight: Ensure best practices in mobile app development, including coding standards, performance optimization, and security measures.',
    'Project Management: Collaborate with product managers, designers, and other stakeholders to define project requirements and deliverables.',
    'Project Management: Manage project timelines, resources, and budgets, ensuring successful delivery of mobile app projects.',
    'Collaboration: Work closely with backend developers to integrate APIs and other services into mobile applications.',
    'Collaboration: Liaise with UX/UI designers to create intuitive and user-friendly interfaces.',
    'Quality Assurance: Implement and manage testing processes to ensure high-quality deliverables, including unit testing, integration testing, and user acceptance testing.',
    'Quality Assurance: Monitor app performance and address any issues that arise post-launch.',
    'Continuous Improvement: Stay updated with the latest trends and technologies in mobile app development.',
    'Continuous Improvement: Evaluate and recommend new tools and technologies to enhance development processes and improve product quality.'
  ];

  skills = [
    'Strong proficiency in mobile development frameworks (e.g., React Native, Flutter, Swift, Kotlin).',
    'Experience with backend integration and working with RESTful APIs.',
    'Familiarity with agile methodologies and project management tools (e.g., JIRA, Trello).',
    'Excellent problem-solving skills and the ability to work under pressure.',
    'Strong communication and interpersonal skills.'
  ];

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }
}