import { Component, OnInit } from '@angular/core';
import { ROUTES } from 'src/app/constants/Routes';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
  Routes = ROUTES;

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }

}
