import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-location',
  templateUrl: './business-location.component.html',
  styleUrls: ['./business-location.component.scss']
})
export class BusinessLocationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });

  }

}
