import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-project-manager-jd',
  templateUrl: './project-manager-jd.component.html',
  styleUrls: ['./project-manager-jd.component.scss']
})
export class ProjectManagerJdComponent implements OnInit {
  selectedJob = 'Project Manager(IT)';

  roles = [
    'Project Planning & Execution: Define project scope, goals, and deliverables that align with organizational objectives.',
    'Project Planning & Execution: Create detailed project plans, schedules, and work breakdown structures.',
    'Project Planning & Execution: Manage project timelines, monitor progress, and make necessary adjustments.',
    'Team Management & Collaboration: Lead and motivate cross-functional teams, ensuring efficient task execution.',
    'Team Management & Collaboration: Ensure all team members are aligned with project objectives and deliverables.',
    'Team Management & Collaboration: Foster clear communication among stakeholders, internal teams, and external vendors.',
    'Risk Management: Identify, assess, and mitigate project risks to ensure smooth project progression.',
    'Risk Management: Develop contingency plans to manage potential project roadblocks.',
    'Budgeting & Resource Management: Monitor project budgets and ensure adherence to financial constraints.',
    'Budgeting & Resource Management: Allocate resources effectively, balancing scope, budget, and time.',
    'Stakeholder Communication: Provide regular updates to stakeholders on project progress, milestones, and potential issues.',
    'Stakeholder Communication: Prepare and present reports to senior management on project status and performance.',
    'Quality Assurance: Ensure project deliverables meet the required quality standards and client expectations.',
    'Quality Assurance: Conduct post-project evaluations and identify areas for future improvement.'
  ];

  skills = [
    '6-9 years of experience in Agile project management, with a strong focus on JIRA or alternative tools.',
    'Certified Scrum Master (CSM), Certified Agile Project Manager (CAPM), or equivalent certifications will be an added advantage.',
    'Proven track record of managing Agile projects using Scrum, Kanban, or other Agile frameworks.',
    'Deep understanding of JIRA/Other for sprint management, task tracking, and reporting.',
    'Strong leadership and team management skills with a focus on Agile principles.',
    'Excellent communication, facilitation, and conflict resolution skills.',
    'Ability to manage multiple projects simultaneously and prioritize effectively.'
  ];

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }
}