import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-devops-jd',
  templateUrl: './devops-jd.component.html',
  styleUrls: ['./devops-jd.component.scss']
})
export class DevopsJdComponent implements OnInit {

  selectedJob = "DevOps Engineer";

  roles = [
    'Design, implement, and maintain cloud-based infrastructure (AWS, Azure, GCP).',
    'Ensure high availability, scalability, and performance of infrastructure and applications.',
    'Develop and manage CI/CD pipelines to automate the software development lifecycle.',
    'Implement infrastructure as code (IaC) using tools like Terraform, Ansible, or CloudFormation.',
    'Set up and manage monitoring and alerting systems using tools like Prometheus, Grafana, ELK stack, or Splunk.',
    ' Monitor system performance, identify issues, and implement solutions to improve stability and performance.',
    'Work closely with development teams to integrate new applications and features into the existing environment',
    'Provide support for development, testing, and production environments.',
    ' Implement security best practices and ensure compliance with industry standards.',
    'Perform regular security assessments and audits.',
    'Write scripts and automation tools to streamline operations and improve efficiency.',
    'Develop and maintain internal tools and utilities to support development and operations.'
  ];

  skills = [
    '5-6 years of demonstrable experience in Java development.',
    'Proficiency with cloud platforms (AWS, Azure, GCP).',
    'Experience with containerization and orchestration tools like Docker and Kubernetes.',
    'Strong knowledge of CI/CD tools such as Jenkins, GitLab CI, CircleCI, or Travis CI.',
    'Proficiency with scripting languages such as Bash, Python, or Ruby.',
    'Experience with infrastructure as code tools like Terraform, Ansible, or CloudFormation.',
    'Familiarity with monitoring and logging tools like Prometheus, Grafana, ELK stack, or Splunk.',
    'Strong analytical and problem-solving skills.',
    'Ability to troubleshoot complex system issues and develop effective solutions.',
    'Familiarity with Agile/Scrum methodologies.',
    'Knowledge of security best practices and experience with security tools.',
    'Familiarity with database management and optimization.',
    'Experience with MySQL and MongoDB database administration'
  ];

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });

  }

}
