import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cariq-insurance-cloud',
  templateUrl: './cariq-insurance-cloud.component.html',
  styleUrls: ['./cariq-insurance-cloud.component.scss']
})
export class CariqInsuranceCloudComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
