export const ROUTES = {
    contact: '/contact',
    leadership: '/leadership',
    careers: '/careers',
    devops_jd: '/career/devops-engineer-requirment',
    android_jd: '/career/android-developer-requirement',
    ios_jd: '/career/ios-developer-requirement',
    java_jd: '/career/java-developer-requirement',
    marcom_jd:'/career/product-marketing-associate',
    customer_support_jd: '/career/inside-sales-and-customer-support-specialist',
    jr_qa_jd: '/career/junior-QA-engineer',
    sr_software_engineer_ios: '/career/senior-software-engineer-ios',
    sr_software_engineer_android: '/career/senior-software-engineer-android',
    business_location: '/business-location',
    projectmanager_jd:'/career/project-manager-requirement',
    mobilelead_jd:'/career/mobile-app-lead-manager',
    productmanager_jd:'/career/product-manager',
    // Products
    cariq_cloud: '/CarIQ-Cloud',
    cariq_insurance_cloud: '/CarIQ-Insurance-Cloud',
    cariq_tls_cloud: '/CarIQ-TLS-Cloud',
    cariq_apps: '/CarIQ-Applications',
    cariq_score: '/CarIQ-Score',
    cariq_varroc_connect_hardware: '/Varroc-Connect-Hardware',
    // Solutions
    cariq_automotive_solution: '/CarIQ-Automotive-Solutions',
    cariq_tls_solution: '/CarIQ-TLS-Solutions',
    cariq_bfsi: '/Banking-finance-services-and-insurance',
    cariq_vehicleusers: '/CarIQ-Vehicle-Users-B2B-Solution',
    //Policy
    about_us: '/about-us',
    privacy_policy: '/privacy-policy',
    refund_and_return: '/refund-and-return',
    shipping_and_cancellation_policy: '/shipping-and-cancellation-policy',
    terms_and_conditions: '/terms-and-conditions',
    cyber_security: '/cyber-security'
};