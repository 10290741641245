import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { CareerPagesRoutingModule } from './career-pages-routing.module';
import { CareersComponent } from './careers/careers.component';
import { DevopsJdComponent } from './job-descriptions/devops-jd/devops-jd.component';
import { AndroidDeveloperJdComponent } from './job-descriptions/android-developer-jd/android-developer-jd.component';
import { IosDeveloperJdComponent } from './job-descriptions/ios-developer-jd/ios-developer-jd.component';
import { JavaDeveloperJdComponent } from './job-descriptions/java-developer-jd/java-developer-jd.component';
import { ApplicationFormComponent } from './job-descriptions/application-form/application-form.component';
import { CareerFormSubmissionComponent } from './job-descriptions/career-form-submission/career-form-submission.component';
import { ProductMarketingAssociateComponent } from './job-descriptions/product-marketing-associate/product-marketing-associate.component';
import { CustomerSupportSpecialistComponent } from './job-descriptions/customer-support-specialist/customer-support-specialist.component';
import { JrQEngineerJdComponent } from './job-descriptions/jr-q-engineer-jd/jr-q-engineer-jd.component';
import { SrSoftwareEngineerIosComponent } from './job-descriptions/sr-software-engineer-ios/sr-software-engineer-ios.component';
import { ProjectManagerJdComponent } from './job-descriptions/project-manager-jd/project-manager-jd.component';
import { MobileappLeadmanagerJdComponent } from './job-descriptions/mobileapp-leadmanager-jd/mobileapp-leadmanager-jd.component';
import { ProductManagerComponent } from './job-descriptions/product-manager/product-manager.component';

@NgModule({
  declarations: [
    CareersComponent,
    DevopsJdComponent,
    AndroidDeveloperJdComponent,
    IosDeveloperJdComponent,
    JavaDeveloperJdComponent,
    ApplicationFormComponent,
    CareerFormSubmissionComponent,
    ProductMarketingAssociateComponent,
    CustomerSupportSpecialistComponent,
    JrQEngineerJdComponent,
    SrSoftwareEngineerIosComponent,
    ProjectManagerJdComponent,
    MobileappLeadmanagerJdComponent,
    ProductManagerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    CareerPagesRoutingModule
  ],
  exports: [
    CareersComponent,
    DevopsJdComponent,
    AndroidDeveloperJdComponent,
    IosDeveloperJdComponent,
    JavaDeveloperJdComponent,
    ApplicationFormComponent,
    CareerFormSubmissionComponent,
    ProductMarketingAssociateComponent,
    CustomerSupportSpecialistComponent,
    JrQEngineerJdComponent,
    SrSoftwareEngineerIosComponent,
    ProjectManagerJdComponent,
    MobileappLeadmanagerJdComponent,
    ProductManagerComponent
  ]
})
export class CareerPagesModule { } 