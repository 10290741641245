import { Component, Input, OnInit } from '@angular/core';
import { CareerService } from 'src/app/service/career.service';

@Component({
  selector: 'app-career-form-submission',
  templateUrl: './career-form-submission.component.html',
  styleUrls: ['./career-form-submission.component.scss']
})
export class CareerFormSubmissionComponent implements OnInit {

  @Input() jobDescription: string;
  finalMessage: string = '';
  finalSubject: string = '';

  careerFormError = false;
  careerFormSuccess = false;

  fileData: any;
  formData = {
    first_name: '',
    last_name: '',
    emailId: '',
    phone: '',
    address: '',
    message: '',
    linkedin: ''
  };
  attachment: File | null = null;

  onFileChange(event: any) {
    this.attachment = event.target.files[0];
    this.fileData = new FormData();
    this.fileData.append('file', this.attachment, this.attachment.name);
  }
  constructor(private apiService: CareerService) { }

  ngOnInit(): void {

  }

  constructFinalSubject(): string {
    return `[JOB] ${this.jobDescription} : ${this.formData.first_name} ${this.formData.last_name}\n`;
  }

  constructFinalMessage(): string {
    return `Name: ${this.formData.first_name} ${this.formData.last_name}\n` +
      `Email: ${this.formData.emailId}\n` +
      `Phone: ${this.formData.phone}\n` +
      `Address: ${this.formData.address}\n\n\n` +
      `Cover Letter: ${this.formData.message}\n` +
      `LinkedIn Profile: ${this.formData.linkedin}`;
  }

  onSubmit() {
    this.finalMessage = this.constructFinalMessage();
    this.finalSubject = this.constructFinalSubject();
    this.apiService.sendFormData(this.formData.emailId, this.finalMessage, this.finalSubject, this.attachment)
      .subscribe(response => {
        this.careerFormSuccess = true;
      }, error => {
        this.careerFormError = true;
      });
  }

}
