import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ios-developer-jd',
  templateUrl: './ios-developer-jd.component.html',
  styleUrls: ['./ios-developer-jd.component.scss']
})
export class IosDeveloperJdComponent implements OnInit {
  selectedJob = 'iOS Developer';
  
  roles = [
    "Design, develop, and maintain advanced applications for the iOS platform.",
    "Utilize design patterns and OOP principles to create robust, scalable, and maintainable code.",
    "Collaborate with cross-functional teams to define, design, and ship new features.",
    "Ensure the performance, quality, and responsiveness of applications.",
    "Identify and correct bottlenecks and fix bugs.",
    "Continuously discover, evaluate, and implement new technologies to maximize development efficiency.",
    "Work on improving application performance and user experience.",
    "Collaborate with UI/UX designers to create user-friendly interfaces.",
    "Write clean, maintainable code and conduct unit tests.",
    "Perform code reviews and troubleshoot/debug applications.",
    "Maintain detailed documentation of the development process, architecture, and standard components.",
    "Create technical specifications and documentation.",
    "Strong knowledge of Swift and Objective-C programming languages.",
    "Proficiency with iOS frameworks such as Core Data, Core Animation, Core Graphics, and Core Text.",
    "Solid understanding of design patterns (e.g., MVC, MVVM) and OOP principles.",
    "Familiarity with cloud message APIs and push notifications.",
    "Understanding of the mobile development life cycle.",
    "Knowledge of the open-source iOS ecosystem and the libraries available for common tasks.",
    "Strong analytical and problem-solving skills.",
    "Ability to think critically and work independently."
  ];

  skills = [
    "Bachelor’s degree in computer science, Engineering, or a related field.",
    "5-6 years of professional experience in iOS development.",
    "Experience with development tools like Xcode and GitHub.",
    "Familiarity with Agile/Scrum methodologies.",
    "Knowledge of Flutter is a plus.",
    "Experience with integrating third-party libraries and APIs.",
    "Experience with RESTful APIs to connect iOS applications to back-end services.",
    "Experience with offline storage, threading, and performance tuning.",
    "Proficiency in version control systems like Git."
  ];


  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }
}
