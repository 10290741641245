import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-varroc-connect-hardware',
  templateUrl: './varroc-connect-hardware.component.html',
  styleUrls: ['./varroc-connect-hardware.component.scss']
})
export class VarrocConnectHardwareComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });

  }

}
