import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { CariqCloudComponent } from './pages/products/cariq-cloud/cariq-cloud.component';
import { CariqInsuranceCloudComponent } from './pages/products/cariq-insurance-cloud/cariq-insurance-cloud.component';
import { CariqTlsCloudComponent } from './pages/products/cariq-tls-cloud/cariq-tls-cloud.component';
import { CariqScoreComponent } from './pages/products/cariq-score/cariq-score.component';
import { CariqApplicationsComponent } from './pages/products/cariq-applications/cariq-applications.component';
import { AutomotiveSolutionComponent } from './pages/solutions/automotive-solution/automotive-solution.component';
import { TlsSolutionComponent } from './pages/solutions/tls-solution/tls-solution.component';
import { VarrocConnectHardwareComponent } from './pages/products/varroc-connect-hardware/varroc-connect-hardware.component';
import { BfsiComponent } from './pages/solutions/bfsi/bfsi.component';
import { VehicleUsersComponent } from './pages/solutions/vehicle-users/vehicle-users.component';
import { AboutUsComponent } from './pages/policy-pages/about-us/about-us.component';
import { PrivacyPolicyComponent } from './pages/policy-pages/privacy-policy/privacy-policy.component';
import { RefundAndReturnComponent } from './pages/policy-pages/refund-and-return/refund-and-return.component';
import { CancellationPolicyComponent } from './pages/policy-pages/cancellation-policy/cancellation-policy.component';
import { TermsAndConditionsComponent } from './pages/policy-pages/terms-and-conditions/terms-and-conditions.component';
import { BusinessLocationComponent } from './pages/business-location/business-location.component';
import { CyberSecurityComponent } from './pages/policy-pages/cyber-security/cyber-security.component';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { BasicLayoutComponent } from './layout/basic-layout/basic-layout.component';

const routes: Routes = [
  {
    path: "",
    component: MainLayoutComponent,
    children: [
      { path: "", component: DashboardComponent },
      { path: "leadership", component: LeaderboardComponent },
      { path: "CarIQ-Cloud", component: CariqCloudComponent },
      { path: "CarIQ-Insurance-Cloud", component: CariqInsuranceCloudComponent },
      { path: "CarIQ-TLS-Cloud", component: CariqTlsCloudComponent },
      { path: "CarIQ-Score", component: CariqScoreComponent },
      { path: "CarIQ-Applications", component: CariqApplicationsComponent },
      { path: "CarIQ-Automotive-Solutions", component: AutomotiveSolutionComponent },
      { path: "CarIQ-TLS-Solutions", component: TlsSolutionComponent },
      { path: "Banking-finance-services-and-insurance", component: BfsiComponent },
      { path: "CarIQ-Vehicle-Users-B2B-Solution", component: VehicleUsersComponent },
      { path: "Varroc-Connect-Hardware", component: VarrocConnectHardwareComponent },
      { path: "contact", component: ContactComponent },
      { path: "about-us", component: AboutUsComponent },
      { path: "privacy-policy", component: PrivacyPolicyComponent },
      { path: "refund-and-return", component: RefundAndReturnComponent },
      { path: "cyber-security", component: CyberSecurityComponent },
      { path: "shipping-and-cancellation-policy", component: CancellationPolicyComponent },
      { path: "terms-and-conditions", component: TermsAndConditionsComponent },
      { path: "business-location", component: BusinessLocationComponent },
      { path: "careers", loadChildren: () => import('./pages/career-pages/career-pages.module').then(m => m.CareerPagesModule) }
    ]
  },
  {
    path: "",
    component: BasicLayoutComponent,
    children: [
      { path: "store", loadChildren: () => import('./store-module/store/store.module').then(m => m.StoreModule) }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
