import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CareersComponent } from './careers/careers.component';
import { DevopsJdComponent } from './job-descriptions/devops-jd/devops-jd.component';
import { JavaDeveloperJdComponent } from './job-descriptions/java-developer-jd/java-developer-jd.component';
import { AndroidDeveloperJdComponent } from './job-descriptions/android-developer-jd/android-developer-jd.component';
import { IosDeveloperJdComponent } from './job-descriptions/ios-developer-jd/ios-developer-jd.component';
import { ProductMarketingAssociateComponent } from './job-descriptions/product-marketing-associate/product-marketing-associate.component';
import { CustomerSupportSpecialistComponent } from './job-descriptions/customer-support-specialist/customer-support-specialist.component';
import { JrQEngineerJdComponent } from './job-descriptions/jr-q-engineer-jd/jr-q-engineer-jd.component';
import { SrSoftwareEngineerIosComponent } from './job-descriptions/sr-software-engineer-ios/sr-software-engineer-ios.component';
import { ProjectManagerJdComponent } from './job-descriptions/project-manager-jd/project-manager-jd.component';
import { MobileappLeadmanagerJdComponent } from './job-descriptions/mobileapp-leadmanager-jd/mobileapp-leadmanager-jd.component';
import { ProductManagerComponent } from './job-descriptions/product-manager/product-manager.component';

const routes: Routes = [
  { path: '', component: CareersComponent },
  { path: 'career/devops-engineer-requirment', component: DevopsJdComponent },
  { path: 'career/java-developer-requirement', component: JavaDeveloperJdComponent },
  { path: 'career/android-developer-requirement', component: AndroidDeveloperJdComponent },
  { path: 'career/ios-developer-requirement', component: IosDeveloperJdComponent },
  { path: 'career/product-marketing-associate', component: ProductMarketingAssociateComponent },
  { path: 'career/inside-sales-and-customer-support-specialist', component: CustomerSupportSpecialistComponent },
  { path: 'career/junior-QA-engineer', component: JrQEngineerJdComponent },
  { path: 'career/senior-software-engineer-ios', component: SrSoftwareEngineerIosComponent },
  { path: 'career/project-manager-requirement', component: ProjectManagerJdComponent },
  { path: 'career/mobile-app-lead-manager', component: MobileappLeadmanagerJdComponent },
  { path: 'career/product-manager', component: ProductManagerComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CareerPagesRoutingModule { } 