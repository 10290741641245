// api.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SupportService {

  private apiUrl = 'https://dev-ecu.mycariq.com/Cariq/notification/support/email';

  constructor(private http: HttpClient) { }

  getPosts(): Observable<any> {
    return this.http.get<any>(this.apiUrl);
  }

  sendFormData(email: string, message: string, subject: string): Observable<any> {
    const formData: FormData = new FormData();
    formData.append('emailId', email);
    formData.append('message', message);
    formData.append('subject', subject);

    const headers = new HttpHeaders({
      'Authorization': 'Basic Y2FyaXFhZG1pbjo1NDUzZjgyOGZiNDEzMTc1ZWQyZjBlOTkxYThjMDdiZQ=='
    });

    headers.append("Content-Type", "application/json");
    return this.http.post<any>(this.apiUrl, { emailId: email, message: message, subject: subject }, { headers });
  }

}
