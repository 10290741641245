import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cariq-score',
  templateUrl: './cariq-score.component.html',
  styleUrls: ['./cariq-score.component.scss']
})
export class CariqScoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });

  }

}
