import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-marketing-associate',
  templateUrl: './product-marketing-associate.component.html',
  styleUrls: ['./product-marketing-associate.component.scss']
})
export class ProductMarketingAssociateComponent implements OnInit {

  selectedJob = 'Lead/Senior Manager- Marketing & Communication';

  roles1 = [
    'Market Research & Insights: Conduct market research to understand consumer needs, market trends, and competitive landscape, and use these insights to inform product positioning and messaging.',
    'Product Positioning: Develop and articulate clear value propositions for products, ensuring that messaging resonates with target audiences across all marketing channels.',
    'Go-to-Market Strategy: Plan and execute go-to-market strategies for new product launches, including cross-functional coordination with product, sales, and creative teams.',
    'Content Development: Collaborate with product and marketing team to create compelling product marketing materials, including brochures, product pages, videos, and case studies.'
  ];

  roles2 = [
    'Campaign Strategy & Execution: Design, manage, and optimize performance marketing campaigns across digital channels, including SEO, SEM, display, social media, and affiliate marketing, to drive user acquisition and retention.',
    'Budget Management: Allocate and manage marketing budgets across channels to achieve the best possible ROI and meet key performance indicators (KPIs).',
    'Analytics & Reporting: Monitor campaign performance, analyze data, and generate insights to continuously optimize marketing efforts and report on key metrics to stakeholders.',
    'A/B Testing: Implement A/B and multivariate tests on creatives, landing pages, and messaging to enhance conversion rates and user engagement.'
  ];

  skills = [
   'Experience: 10+ years of experience in a combined product marketing and performance marketing role within the B2C technology sector.',
   'Product Marketing Expertise: Proven track record in product positioning, go-to-market strategy, and creating compelling product content.',
   'Performance Marketing Expertise: Strong hands-on experience with digital marketing tools and platforms such as Google Ads, Facebook Ads, and Google Analytics.',
   'Analytical Mindset: Proficient in data analysis, with the ability to translate data into actionable marketing strategies.',
   'Creative Thinking: Ability to craft compelling narratives and innovative marketing campaigns that drive product awareness and adoption.',
   'Collaboration Skills: Strong team player with the ability to work cross-functionally and manage multiple stakeholders.'
  ];

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }
}
