import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ROUTES } from 'src/app/constants/Routes';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  Routes = ROUTES;
  @ViewChild('testimonialContainer') testimonialContainer: ElementRef;
  isButtonVisible = false;

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.isButtonVisible = window.scrollY > 650;
  }

  scrollLeft() {
    this.testimonialContainer.nativeElement.scrollBy({
      left: -this.testimonialContainer.nativeElement.offsetWidth,
      behavior: 'smooth'
    });
  }

  scrollRight() {
    this.testimonialContainer.nativeElement.scrollBy({
      left: this.testimonialContainer.nativeElement.offsetWidth,
      behavior: 'smooth'
    });
  }

  scrollToElement($element): void {
    $element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }
  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior:'smooth' });
  }
}
