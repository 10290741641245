import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeaderboardComponent } from './pages/leaderboard/leaderboard.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ContactComponent } from './pages/contact/contact.component';
import { CariqCloudComponent } from './pages/products/cariq-cloud/cariq-cloud.component';
import { CariqInsuranceCloudComponent } from './pages/products/cariq-insurance-cloud/cariq-insurance-cloud.component';
import { CariqTlsCloudComponent } from './pages/products/cariq-tls-cloud/cariq-tls-cloud.component';
import { CariqScoreComponent } from './pages/products/cariq-score/cariq-score.component';
import { CariqApplicationsComponent } from './pages/products/cariq-applications/cariq-applications.component';
import { AutomotiveSolutionComponent } from './pages/solutions/automotive-solution/automotive-solution.component';
import { TlsSolutionComponent } from './pages/solutions/tls-solution/tls-solution.component';
import { VarrocConnectHardwareComponent } from './pages/products/varroc-connect-hardware/varroc-connect-hardware.component';
import { BfsiComponent } from './pages/solutions/bfsi/bfsi.component';
import { VehicleUsersComponent } from './pages/solutions/vehicle-users/vehicle-users.component';
import { AboutUsComponent } from './pages/policy-pages/about-us/about-us.component';
import { PrivacyPolicyComponent } from './pages/policy-pages/privacy-policy/privacy-policy.component';
import { RefundAndReturnComponent } from './pages/policy-pages/refund-and-return/refund-and-return.component';
import { ShippingComponent } from './pages/policy-pages/shipping/shipping.component';
import { CancellationPolicyComponent } from './pages/policy-pages/cancellation-policy/cancellation-policy.component';
import { TermsAndConditionsComponent } from './pages/policy-pages/terms-and-conditions/terms-and-conditions.component';
import { BusinessLocationComponent } from './pages/business-location/business-location.component';
import { CareersComponent } from './pages/career-pages/careers/careers.component';
import { DevopsJdComponent } from './pages/career-pages/job-descriptions/devops-jd/devops-jd.component';
import { AndroidDeveloperJdComponent } from './pages/career-pages/job-descriptions/android-developer-jd/android-developer-jd.component';
import { IosDeveloperJdComponent } from './pages/career-pages/job-descriptions/ios-developer-jd/ios-developer-jd.component';
import { JavaDeveloperJdComponent } from './pages/career-pages/job-descriptions/java-developer-jd/java-developer-jd.component';
import { ApplicationFormComponent } from './pages/career-pages/job-descriptions/application-form/application-form.component';
import { CyberSecurityComponent } from './pages/policy-pages/cyber-security/cyber-security.component';
import { CareerFormSubmissionComponent } from './pages/career-pages/job-descriptions/career-form-submission/career-form-submission.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { BasicLayoutComponent } from './layout/basic-layout/basic-layout.component';
import { StoreModule } from './store-module/store/store.module';

@NgModule({
  declarations: [
    AppComponent,
    LeaderboardComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    ContactComponent,
    CariqCloudComponent,
    CariqInsuranceCloudComponent,
    CariqTlsCloudComponent,
    CariqScoreComponent,
    CariqApplicationsComponent,
    AutomotiveSolutionComponent,
    TlsSolutionComponent,
    VarrocConnectHardwareComponent,
    BfsiComponent,
    VehicleUsersComponent,
    AboutUsComponent,
    PrivacyPolicyComponent,
    RefundAndReturnComponent,
    ShippingComponent,
    CancellationPolicyComponent,
    TermsAndConditionsComponent,
    BusinessLocationComponent,
    CareersComponent,
    DevopsJdComponent,
    AndroidDeveloperJdComponent,
    IosDeveloperJdComponent,
    JavaDeveloperJdComponent,
    ApplicationFormComponent,
    CyberSecurityComponent,
    CareerFormSubmissionComponent,
    MainLayoutComponent,
    BasicLayoutComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    AppRoutingModule,
    StoreModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
