import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-java-developer-jd',
  templateUrl: './java-developer-jd.component.html',
  styleUrls: ['./java-developer-jd.component.scss']
})
export class JavaDeveloperJdComponent implements OnInit {

  selectedJob = 'Java Developer';

  roles = [
    'Develop, test, and deploy robust, scalable server-side applications using Java and Spring Boot.', 'Create and maintain TCP/IP, MQTT based server side applications using Node.js.',
    'Implement message-driven architecture using Kafka and MQTT.',
    'Integrate various back-end services and databases.',
    'Ensure seamless integration of front-end and back-end systems.',
    'Work closely with cross-functional teams including product managers, designers, and other developers to gather requirements and ensure timely project delivery.',
    'Participate in code reviews, design discussions, and provide constructive feedback.',
    'Troubleshoot, debug, and upgrade existing applications.',
    'Provide support for system and application issues.',
    'Create and maintain detailed technical documentation.',
    'Ensure compliance with industry best practices and company standards'  
  ];

  skills = [
    '5-6 years of demonstrable experience in Java development.',
    'Proficiency in Java and Spring Boot.',
    'Experience with Node.js.',
    'Strong knowledge of Kafka for real-time data streaming.',
    'Understanding of TCP/IP and MQTT protocol for IoT applications.',
    'Familiarity with database technologies such as SQL and NoSQL databases.',
    'Experience with RESTful API development.',
    'Proficiency in version control systems like Git.',
    'Strong analytical and problem-solving skills.',
    'Ability to quickly understand complex systems and develop innovative solutions.',
    'Experience working in Agile/Scrum development cycles.',
    'Knowledge of microservices architecture.',
    'Understanding of security best practices in application development.'
  ];

  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });
  }
}
