import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-automotive-solution',
  templateUrl: './automotive-solution.component.html',
  styleUrls: ['./automotive-solution.component.scss']
})
export class AutomotiveSolutionComponent implements OnInit {
  sholutionToShow = 0;
  constructor() { }

  ngOnInit() {
    window.scrollTo({ top: 0, behavior: 'auto' });

  }

  showCarIQAutomotiveSolution(solution) {
    this.sholutionToShow = solution;
  }

}
